import React from "react";
import blog1Data from "data/elf/blogs.json";
import Navbar from "components/Navbar/navbar";
import BlogStanderd from "components/Blog-standerd/blog-standerd";
import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";
import DarkTheme from "layouts/Dark";

const Blogs = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="Our Blogs."
        paragraph="Current tech updates of our creative team."
      />
      <h1 className="d-none">Our Blogs.</h1>
      <BlogStanderd blogs={blog1Data} />
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Blogs - Elfonze Technologies</title>
      <meta key="description" name="description"
        content="Discover diverse perspectives, insightful commentary, and engaging narratives in our collection of blog posts. Explore topics ranging from technology and culture to lifestyle and beyond, crafted to inform, inspire, and entertain." />
    </>
  )
}

export default Blogs;
